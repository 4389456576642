@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.adobe.com/fonts/europa:italic,400,100');


@layer base {
    body {
        @apply font-[europa];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }

}
.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: url("./assets/ball.png");
    


}

.content-div1 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div1:hover {
    background-image: url("./assets/slwebsite.png");

}

.content-div2 {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div2:hover {
    background-image: url("./assets/Webflow.png");

}

body { 
    padding: 0;
    margin: 0;
    background-color:white;
}

.mycanvas {
    height: 50px;
    width: 50px;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.container{
    width: 57px;
    height: 50px;
}

.ball{
    background-color: pink;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    left: 90%;
    top: 50vh;
    animation: bounce 3.0s infinite;
}


.ball1{
    background-color: #FFBDEA;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    left: 5%;
    top: 30vh;
    animation: bounce 1.3s infinite;
}


.ball2 {
    background-color: #FFDCB8;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    left: 70%;
    top: 50vh;
    animation: bounce 1.9s infinite;
}

.ball3 {
    background-color: #FFDCB8;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: absolute;
    left: 20%;
    top: 50vh;
    animation: bounce 3.5s infinite;
}


@keyframes bounce{
    10%{
    height: 50px;
    width: 50px;

    }
    30%{
        height: 50px;
    width: 50px;

    }
    50%{
        height: 30px;
    width: 57px;
    transform: translateY(240px);
    }

    75%{
        height: 50px;
    width: 57px;
    }

    100%{
        transform: translateY(0px);
    }
}
   
                    @keyframes shrink{
    50%{
transform: scaleX(0.9);
    }
}


.wrapper {
    height: 10vh;
    position: absolute;
    top: 25vh;
    left: 8%;
}

.wrapper1 {
    height: 10vh;
    position: absolute;
    top: 90vh;
    left: 43%;
}
.typing-demo1 {
    width: 22ch;
    animation: typing 6ns steps(22), step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
 
    font-family: monospace;
    font-size: 2em;
    color: white;
  }
  
  .typing-demo {
    width: 22ch;
    animation: typing 6s steps(22), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 2em;
    color: white;
  }
  
  @keyframes typing {
    from {
      width: 0
    }
  }
      
  @keyframes blink {
    50% {
      border-color: transparent
    }
  }